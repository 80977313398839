import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Hero from '@components/content/hero/Hero';
import './styles.scss';

const StudySummaries = () => {
	return (
		<Layout>
			<Seo title="Orphan | EMFLAZA® (deflazacort)" description=" "/>
			<div className='relative min-h-screen page--orphan-study-summaries'>
				<Hero
					heroImage='hero-orphan.png'
					title='Study Summaries'
					addedClass='blue-bg mt-5'
					useBreadcrumbs={false}
				/>

				<div className='px-8 text-sm sm:max-w-4xl lg:px-0 lg:pl-20 text-emflaza-blue-500' >
					<div className='mb-20'>
						<h3 className='mb-5 text-base font-bold'>Griggs 2016 Study Summary</h3>
						<h5 className='font-bold'>Efficacy and safety of deflazacort vs prednisone and placebo for Duchenne muscular dystrophy</h5>
						<p>A phase &#8546;, double&#8209;blind, randomized, placebo&#8209;controlled, multicenter study of 196 patients with DMD aged 5 to 15 years old.</p>
						<p>The primary efficacy endpoint was average change in muscle strength from baseline to Week 12 compared with placebo. At Week 12, all active treatment arms (deflazacort 0.9 and 1.2&nbsp;mg/kg/day and prednisone 0.75 mg/kg/day) preserved muscle strength in patients with DMD.</p>
						<p>In a secondary endpoint analysis, deflazacort 0.9&nbsp;mg/kg/day demonstrated a significant improvement in muscle strength vs prednisone from Week 12 to 52.</p>
						<p>Deflazacort preserved motor function in patients with DMD. From baseline to Week 52, an exploratory endpoint showed that patients receiving deflazacort had significant improvements in the time to climb 4 stairs compared with prednisone. From Week 12 to Week 52, both deflazacort groups demonstrated greater numerical improvements in time from supine to stand, time to climb 4 stairs, and time to run or walk 30 feet compared with the prednisone&#8209;treated participants, but these did not reach significance since the trial was not statistically designed to detect this difference.</p>
						<p>The 3 most commonly reported treatment&#8209;related adverse events (AEs) were Cushingoid appearance, erythema, and hirsutism. Weight gain&#8209;related AEs were more likely to be moderate or severe with prednisone compared with deflazacort. There were more AEs, serious AEs, and AEs leading to discontinuation in prednisone&#8209;treated patients than in either deflazacort&#8209;treated cohort. Overall, psychiatric AEs were more common in the prednisone group than in the deflazacort groups; however, psychotic disorder was reported more frequently in the deflazacort 0.9 mg/kg/day group. Cataracts occurred at a higher rate with daily deflazacort than prednisone.</p>
						<h5 className='mt-5 font-bold'>Study Limitations</h5>
						<p>Comparisons between deflazacort and prednisone are not included in the approved Prescribing Information for deflazacort because prednisone is not an approved treatment for&nbsp;DMD. This study examined the recommended deflazacort dose of 0.9 mg/kg/day as well as a dose of 1.2 mg/kg/day. The study had a 52&#8209;week duration of treatment, which did not allow for long-term follow&#8209;up to observe the effect of steroids over longer periods of time, and was not powered to achieve significance. The deflazacort and prednisone doses administered in this study were from various manufacturers in and outside the United States.</p>
						<h5 className='mt-5 font-bold'>Financial Disclosures of Study Sponsors</h5>
						<p>This study was sponsored with funding provided by Nordic Merrill Dow and the Muscular Dystrophy Association (MDA).</p>
						<p className='mt-5 mb-6'>Griggs RC, Miller JP, Greenberg CR, et al. Efficacy and safety of deflazacort vs prednisone and placebo for Duchenne muscular dystrophy. <em>Neurology</em>. 2016;87(20):2123-2131.</p>
					</div>

					<div className='mb-20'>
						<h3 className='my-5 text-base font-bold'>Bello 2015 Study Summary</h3>
						<h4 className='font-bold'>Prednisone/prednisolone and deflazacort regimens in CINRG Duchenne Natural History Study</h4>
						<p>
							A natural history observational prospective study evaluating age at
							LoA and side-effect profiles associated with different glucocorticoid corticosteroid
							regimens in DMD. The study included 340 DMD participants, aged 2-28 years,
							who were enrolled in the CINRG-DNHS.
							LoA was defined as continuous wheelchair use, confirmed by inability to
							walk 10 minutes unaided. Effects of prednisone or PRED/DFZ,
							administration frequency, and dose were analyzed by time-varying Cox regression.
							Side-effect frequencies were compared using the chi-square test. Average dose of daily
							PRED administered while ambulatory (n=94) was  75% &plusmn; 17% of recommended,
							which was lower than daily DFZ (83% &plusmn; 15%, n=80).
						</p>
						<p>
							Participants treated &ge;1 year while ambulatory (n=252/340) showed a 3-year median delay in LoA. Fourteen different regimens were observed. Nondaily treatment was common for PRED (37%) and rare for DFZ (3%). DFZ was associated with later LoA than PRED (hazard ratio 0.294 &plusmn; 0.053 vs 0.490 &plusmn; 0.08; 2-year difference in median LoA with daily administration). Average dose was lower for daily PRED (0.56 mg/kg/day, 75% of recommended) than daily DFZ (0.75 mg/kg/day, 83% of recommended). DFZ showed higher frequencies of growth delay, Cushingoid appearance, and cataracts, but not weight gain.
						</p>
						<p>
							For comparisons of median LoA between glucocorticoid
							corticosteroid-treated and untreated participants, “glucocorticoid
							corticosteroid-treated” only included those patients who had been
							administered glucocorticoid corticosteroids for &ge;1 year, starting
							1≥ year before LoA. Side-effect frequency was calculated in 277
							participants (86.2%) with any treatment duration while ambulatory.
							Weight gain (65%), Cushingoid appearance (55%), growth delay (37%),
							behavior changes (37%), low bone mass density and/or fracture (22%),
							cataracts (15%), and skin abnormalities (13%) were most frequently reported.
							Some frequencies might be underestimated because side effects were
							recorded for only the 3 most recent glucocorticoid corticosteroid
							regimens before study baseline. Weight gain frequency was similar
							for daily DFZ and daily PRED, but daily DFZ showed higher incidence
							of Cushingoid appearance (72% vs 50%), growth delay (60% vs 27%), and cataracts (29% vs 5%).
						</p>

						<h4 className='mt-4 font-bold'>Study Limitations</h4>
						<p>Based upon the study design and enrollment, the following limitations should be considered:</p>
						<ul className='bulleted-list'>
							<li>Steroid use was not randomized as might be in a controlled clinical trial</li>
							<li>Comparisons between DFZ and PRED are not included in the approved Prescribing Information for DFZ, as PRED is not an approved treatment for&nbsp;DMD</li>
							<li>The analyses included a comparison of multiple glucocorticoid agents and regimens (such as daily vs intermittent dosing)
							</li>
							<ul className="list-none mb-3">
								<p className="flex text-xs"><span className="pr-1">—</span> Fourteen distinct regimens of PRED or DFZ were observed</p>
								<p className="flex text-xs"><span className="pr-1">—</span> An in-depth analysis of specific doses or schedules was not feasible in a long-term, prospective, observational study</p>
							</ul>
							<li>A “historical” improvement in care was noted, showing a parallel increase in the frequency of glucocorticoid prescriptions for DMD and implementation of other standards of care such as physical therapy, management of joint contractures, and bone fracture prevention</li>
							<li>DFZ and PRED utilized in this study were from various manufacturers in and outside the United&nbsp;States</li>
							<li>Potential differences were noted in socioeconomic status of patients and caregivers—DFZ was not commercially available in the United States at the time of the study</li>
						</ul>


						<h4 className='mt-4 font-bold'>Financial Disclosures of Study Sponsors</h4>
						<p>This project was funded through the Italian Ministry of Education PhD grant awarded to L.B. for the 28th Cycle of the Doctorate School of Medical, Clinical, and Experimental Science at the University of Padova, Italy; and grants from the NIH (U54HD053177, R24HD050846, UL1RR031988, UL1RR024992, U54RR026139, G12RR003051, 1R01AR061875, RO1AR062380), the US Department of Education/NIDRR (H133B031118, H133B090001), the US Department of Defense (W81XWH-09-1-0592).</p><br />
						<p>Bello L, Gordish-Dressman H, Morgenroth LP, et al; CINRG <span className="whitespace-nowrap">Investigators. Prednisone/prednisolone</span> and deflazacort regimens in the CINRG Duchenne Natural History Study. <em>Neurology</em>. 2015;85(12):1048-1055.</p>
					</div>

					<div className='mb-20'>
						<h3 className='my-5 text-base font-bold'>Marden 2020 Study Summary</h3>
						<h4 className='font-bold'>Real-world outcomes of long-term prednisone and deflazacort use in patients with Duchenne muscular dystrophy: experience at a single, large care center</h4>
						<p>
							A retrospective study assessing outcomes among patients with DMD receiving
							deflazacort or prednisone in real-world practice. 435 DMD patients,
							aged 4 years and older, who attended the CCHMC from 2004
							to March 2017 were studied retrospectively using time&#8209;to&#8209;event and regression analyses.
							Loss of ambulation was identified as functional mobility scale (FMS) score &gt;4, which
							is indicative of patients’ full-time use of a wheelchair for mobility in DMD. Age at onset
							of scoliosis was defined as the first recorded clinical diagnosis of scoliosis in the patient’s
							medical record. Age at first occurrence of FMS &gt;4 and first diagnosis of scoliosis were
							studied in time&#8209;to&#8209;event analyses, with age as the time variable, and censoring at the end
							of data availability. Kaplan-Meier curves and log-rank tests were calculated to compare these
							outcomes between steroid initiation groups. Cox proportional hazards analyses were used to estimate
							the association between steroid type and age at event while adjusting for age at steroid initiation,
							the calendar year during which the patient had initiated steroid use, and whether the patient had initiated
							steroids prior to or after their first clinic visit at CCHMC. The adjusted analyses thus accounted
							for differences in the duration of steroid exposure, potential temporal trends that might
							impact outcomes across groups, and potential differences in care received prior to CCHMC.
							Sensitivity analyses were conducted distinguishing between patients who received daily
							versus other regimens of prednisone and, separately, distinguishing those who maintained
							prednisone versus those who switched to deflazacort after initiation.
						</p>

						<p>
							Among n = 600 total boys in the CCHMC database, n = 559
							had their date of steroid initiation recorded and n = 500 had
							a steroid type recorded at some point during their follow-up history at CCHMC.
							A further n = 435 of these patients had at least one assessment of
							FMS or an assessment for scoliosis. The overall average follow-up included in
							the time&#8209;to&#8209;event analyses was 11.2&nbsp;years in the FMS analyses and 11.9&nbsp;years
							in the scoliosis analyses. The follow-up time was similar across prednisone-
							and deflazacort-initiated patients (&#x3C;6 months difference in mean follow-up time).
							At first clinic visit at CCHMC with functional data recorded, deflazacort-initiated
							patients were on average younger, shorter and weighed less, compared with prednisone-initiated patients.
							The deflazacort-initiated group was also younger on average at steroid initiation
							compared with the prednisone-initiated group (5.7 vs 6.4 years, respectively).
							Across measures of ambulatory, pulmonary and cardiac function at the first clinic visit at CCHMC,
							deflazacort-treated patients tended to have better or similar function compared with prednisone-treated patients.
						</p>

						<h4 className='mt-4 font-bold'>Study Limitations</h4>
						<p>Steroid use was not randomized as might be in a controlled clinical trial. Therefore:</p>
						<ul className='bulleted-list'>
							<li>Differences in outcomes between steroid groups could be confounded by factors that differ between these groups</li>
							<li>It could also be hypothesized that differences in care received prior to CCHMC, including potential differences in steroid dosing or supportive care, could have impacted outcomes</li>
							<li>A higher proportion of patients initiating prednisone did so prior to CCHMC compared with those initiating deflazacort it is possible that unobserved differences are present</li>
							<li>Cost has been a factor for most families at CCHMC who opt for prednisone instead of out&#8209;of&#8209;pocket, imported deflazacort</li>
							<li>The impact of different dosing regimens is a plausible contributor to the differences in outcomes observed in the present study between patients receiving prednisone and deflazacort</li>
							<li>Ambulatory test results were not available from patients who were unable or unwilling to complete the assessments</li>
						</ul>

						<h4 className='mt-4 font-bold'>Financial Disclosures of Study Sponsors</h4>
						<p>
							This study was facilitated by cTAP with funding from PTC.
							cTAP is a pre-competitive coalition of academic clinicians,
							drug developers and patient foundations formed in 2015 to overcome challenges
							affecting clinical trials in DMD. cTAP has received sponsorship
							from Astellas Pharma (Mitobridge), BioMarin, Biophytis,
							Bristol-Myers Squibb, Catabasis, FibroGen, Inc.,
							Italfarmaco SpA, Marathon Pharmaceuticals, Pfizer, Inc., PTC Therapeutics,
							Roche, Sarepta Therapeutics, Shire plc, Solid Biosciences, Summit Plc.,
							Wave Life Sciences, Parent Project Muscular Dystrophy,
							Charley’s Fund and CureDuchenne, a founding patient advocacy partner
							and provider of initial seed funding to cTAP.
						</p>
						<p className='pt-4'>
							Marden JR, Freimark J, Yao Z, Signorovitch J, Tian C, Wong BL.
							Real-world outcomes of long-term prednisone and deflazacort use
							in patients with Duchenne muscular dystrophy: experience at a single,
							large care center. <span className='italic'>J Comp Eff Res</span>. 2020;9(3):177-189.
						</p>
					</div>

					<div className='mb-20'>
						<h3 className='my-5 text-base font-bold'>McDonald 2018 Study Summary</h3>
						<h4 className='font-bold'>Long-term effects of glucocorticoids on function, quality of life, and survival in patients with Duchenne muscular dystrophy: a prospective cohort study</h4>
						<p>
							A prospective natural history observational cohort study with 440 patients ages 2 to 28 years,
							which evaluated the long-term effects of glucocorticoids in DMD. Patients were followed up for 10 years.
							Patients were stratified by duration of glucocorticoid treatment (&#x3c;1 month or ≥12&nbsp;months). Milestones
							for 9 groups of the Davis Duchenne Functional assessments were recorded and analyzed using
							Kaplan-Meier to measure disease progression.</p>
							<p>The study also compared patients treated with prednisone
							or prednisolone (PRED) versus those treated with deflazacort (DFZ) for age at loss of milestones.
							Patients with cumulative glucocorticoid treatment duration of 1&nbsp;year or longer had consistently delayed
							occurrence of ambulatory disease progression milestones by periods of 2.1 to 4.4 years compared with
							glucocorticoid-naive patients or those treated for less than 1&nbsp;month (all log-rank, apart from time
							to climb four stairs). Glucocorticoid treatment for 1&nbsp;year or longer was associated with significantly
							delayed loss of upper limb milestones by
							2.8 to 8.0 years compared with treatment for less than 1&nbsp;month. DFZ was associated with increased
							median age at loss of three milestones (age at: loss of ability to stand from supine,
							loss of ambulation, loss of hand-to-mouth function with retained hand function) by 2.1 to 2.7
							years in comparison with PRED (log-rank). The mean age at loss of the earliest milestones
							typically reached (ie, supine to stand &ge;5 seconds and supine to stand &ge;10 seconds) showed
							trends in delayed loss of function favoring deflazacort, but differences between agents were not significant.
							Insufficient numbers of milestone transitions were observed to allow a comparison between glucocorticoid
							regimens for loss of distal hand function. The most common side effects of glucocorticoid
							treatment were weight gain, Cushingoid features, behavior changes, growth delay, fractures,
							cataracts, and skin changes.
						</p>

						<h4 className='mt-4 font-bold'>Study Limitations</h4>
						<p>Based upon the study design and enrollment, the following limitations should be considered:</p>
						<ul className='bulleted-list'>
							<li>Steroid use was not randomized as might be in a controlled clinical trial</li>
							<li>Comparisons between DFZ and PRED are not included in the approved Prescribing Information for DFZ, as PRED is not an approved treatment for&nbsp;DMD</li>
							<li>The analyses included a comparison of multiple glucocorticoid agents and regimens (such as daily vs intermittent dosing)</li>
							<li>An in-depth analysis of specific doses or schedules was not feasible in a long-term, prospective, observational, natural history study</li>
							<li>DFZ and PRED utilized in this study were from various manufacturers in and outside the United&nbsp;States</li>
							<li>Potential differences were noted in socioeconomic status of patients and caregivers—DFZ was not commercially available in the United States at the time of the study</li>
							<li>DFZ may have been used more frequently in a daily dosing regimen</li>
							<li>The daily doses of DFZ used in the population were closer to that of the benchmarked FDA-approved labeled dose of 0.90 mg/kg/day</li>
						</ul>


						<h4 className='mt-4 font-bold'>Financial Disclosures of Study Sponsors</h4>
						<p>This study was supported by US Department of Education/NIDRR (#H133B031118, #H133B090001); US Department of Defense (#W81XWH-12-1-0417); National Institutes of Health/NIAMS (#R01AR061875); and Parent Project Muscular Dystrophy.
						</p>
						<p className='pt-4'>McDonald CM, Henricson EK, Abresch RT, et al; CINRG Investigators. Long-term effects of glucocorticoids on function, quality of life, and survival in patients with Duchenne muscular dystrophy: a prospective cohort study. <span className='italic'>Lancet</span>. 2018;391(10119):451-461.
						</p>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default StudySummaries;
